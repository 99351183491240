<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <div class="d-flex justify-content-end">
        <WButton info
          v-if="!isDisable"
          v-show="!showStatistics"
          @click="openModal(true)"
        >
          {{ $t('vouchers.add') }}
        </WButton>
        <WButton :warning="showStatistics"
          customClass="ml-q"
          @click="toggleShowStatistics"
        >
          {{ showStatistics ? $t('vouchers.backToVouchers') : $t('portalStats.stats') }}
        </WButton>
      </div>

<!--      <Table-pagination-v2-->
<!--        v-if="!showStatistics"-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'account', label: $t('vouchers.account') },-->
<!--          { value: 'tariff', label: $t('vouchers.tariff') },-->
<!--          { value: 'expire_at', label: $t('vouchers.expire_at') },-->
<!--          { value: 'used', label: $t('vouchers.used') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        v-if="!showStatistics && showTablePaginationV3Component"
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'account', label: $t('vouchers.account') },
          { value: 'tariff', label: $t('vouchers.tariff') },
          { value: 'expire_at', label: $t('vouchers.expire_at') },
          { value: 'used', label: $t('vouchers.used') }
        ]"
      />
    </div>
    <div class="col-lg-4" v-if="!showStatistics">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <WButton secondary outline sm
                      v-if="isFiltersActive"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </WButton>
                  </div>
                </div>
              </div>

    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portalvouchers" v-if="isVouchersLoading"></div>-->
        <div v-if="isVouchersLoading"><Loader-spinner /></div>
        <div class="card-header d-flex justify-content-between">
          <div>
            <i class="glyphicons glyphicons-fees-payments glyphicons--shrink mr-0"></i>
            {{ $t('vouchers.title') }}
            <span class="text-muted ml-3" v-if="!showStatistics && vouchersList.length">
              {{ this.vouchersList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultVouchers }}
            </span>
          </div>
        </div>
        <div v-if="!showStatistics" class="card-block card-block__table table-responsive">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.account') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.tariff') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.duration') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.code') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.used') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(voucher, index) in vouchersList" :key="voucher.id" @click="openModal(false, voucher)">
                <td class="align-middle">{{ index + 1 }}</td>
                <td class="align-middle">
                  <!--                  <span v-if="voucher.account">-->
                  <!--                    {{ getAccountNameByID(voucher.account) || '-' }}-->
                  <!--                  </span>-->
                  <span v-if="voucher.identity">
                    {{ voucher.identity || '-' }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span>
                    {{ getTariffNameByID(voucher.tariff) }}
                  </span>
                </td>
                <td class="align-middle">
                  <span v-if="voucher.start_at || voucher.expire_at">
                    {{ getDuration(voucher.start_at, voucher.expire_at) || '-' }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span v-if="voucher.code">
                    {{ voucher.code }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <h5>
                    <span
                      class="badge font-weight-normal"
                      :class="{ 'badge-success': voucher.used === true, 'badge-warning': voucher.used === false }"
                    >
                      {{ voucher.used ? $t('general.yes') : $t('general.no') }}
                    </span>
                  </h5>
                </td>
              </tr>
              <tr v-if="vouchersList.length === 0">
                <td colspan="7" class="no-data-row">
                  <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div>-->
          <div
            v-if="
              this.canLoadMoreVouchers &&
              this.vouchersList.length &&
              this.totalResultVouchers - this.vouchersList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultVouchers - this.vouchersList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultVouchers - this.vouchersList.length }}</span>
              {{ this.$t('portal.paginationVouchersOutOf') }} {{ this.totalResultVouchers - this.vouchersList.length }}
            </WButton>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div v-if="showStatistics" class="card-block">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <section>
            <div class="row">
              <div class="col-lg-2 col-sm-2">
                <div class="filter">
                  <label>{{ $t('portalStats.accounts') }}</label>
                  <div class="input-group input-group filters__location">
                    <!--                    <multiselect-->
                    <!--                      :multiple="false"-->
                    <!--                      :allowEmpty="false"-->
                    <!--                      v-model="filters.accounts"-->
                    <!--                      :options="userAccountsStatList"-->
                    <!--                      label="identity"-->
                    <!--                      :value="userAccountsStatList[0]"-->
                    <!--                      trackBy="identity"-->
                    <!--                      @input="changeProfile"-->
                    <!--                      :closeOnSelect="true"-->
                    <!--                      :placeholder="$t('portalStats.selectAccount')"-->
                    <!--                      searchable-->
                    <!--                      :show-labels="false"-->
                    <!--                    ></multiselect>-->

                    <SelectComponentWithDeleteButton
                      v-if="isShowingAccountsFilter"
                      @select="selectAccount"
                      @unselect="unselectAccount"
                      class="w-100 account-select-in-vouchers-filters"
                      v-model="filters.accounts"
                      track-by="identity"
                      option-id-name="id"
                      :async-function="requestAccounts.bind(this)"
                      :placeholder="$t('vouchers.allAccounts')"
                    ></SelectComponentWithDeleteButton>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-sm-2">
                <div class="filter">
                  <label>{{ $t('portalStats.tariffs') }}</label>
                  <div class="input-group input-group filters__location">
                    <multiselect
                      :multiple="false"
                      :allowEmpty="false"
                      v-model="filters.tariffs"
                      :options="tariffsStatList"
                      label="name"
                      trackBy="name"
                      @input="changeProfile"
                      :closeOnSelect="true"
                      :placeholder="$t('portalStats.selectTariff')"
                      :show-labels="false"
                    ></multiselect>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-sm-4">
                <div class="filter">
                  <label>{{ $t('portalStats.range') }}</label>
                  <div class="input-group input-group filters__location">
                    <date-picker
                      class="datepicker_width_full datepicker_height_40"
                      v-model="drPickerDate"
                      type="date"
                      :onChange="handleDateChangeForStat.bind(this)"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-sm-2 submit-width_full">
                <WButton :success="!submitButton"
                  @click="getVouchersStatAccountsAndTarifs()"
                  :disabled="submitButton"
                >
                  {{ $t('marketing.submit') }}
                </WButton>
              </div>
              <div class="col-lg-2 col-sm-2 submit-width_full">
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  v-if="drPickerDate"
                  class="download-button-in-voucher-stats float-right"
                  :data="formatPieToCSV(tariffsChartPie, $t('portalStats.tariffs'))"
                  :data-for-excel="formatPieToExcel(tariffsChartPie, $t('portalStats.tariffs'))"
                  excel-file-type="xlsx"
                  :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalVouchers'))"
                  :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalVouchers'))"
                  :downloads-formats-for-show="{
                    oldCsv: true,
                    newCsv: false,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
              </div>
            </div>
          </section>
          <div class="row">
            <section class="section__stats col-lg-6 col-md-6">
              <highcharts :options="tariffsChartPie" id="tariffsChartPie"></highcharts>
            </section>
            <section class="section__stats col-lg-6 col-md-6">
              <h4 class="mt-2">{{ $t('portalStats.vouchersAmount') }}: {{ vouchersAmount }}</h4>
              <SavePngButton
                class="save-icon color-brand"
                id="tariffsChartPie"
                :name="getFileNameForDownloadFiles($t('downloadFilesNames.portalVouchers'))"
              />
              <div class="col-12 row mt-1">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="tariff-cell">{{ $t('vouchers.tariff') }}</th>
                      <th>{{ $t('portalStats.count') }}</th>
                      <th>{{ $t('portalStats.percent') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="tariffsChartPie.series[0].data.length"
                      v-for="(tariff, index) in tariffsChartPie.series[0].data"
                      :key="index"
                    >
                      <td v-if="tariff.name !== $t('portalStats.other')" class="tariff-cell">{{ tariff.name }}</td>
                      <td v-if="tariff.name !== $t('portalStats.other')">{{ tariff.amount }}</td>
                      <td v-if="tariff.name !== $t('portalStats.other')">{{ tariff.y.toFixed(2) }}%</td>
                    </tr>
                    <tr
                      v-if="tariffsChartPie.series[0].data.length"
                      v-for="(tariff, index) in tariffsChartPie.series[0].data"
                      :key="tariff.name"
                    >
                      <td v-if="tariff.name === $t('portalStats.other')" class="tariff-cell">
                        {{ $t('portalStats.other') }}
                      </td>
                      <td v-if="tariff.name === $t('portalStats.other')">{{ tariff.amount }}</td>
                      <td v-if="tariff.name === $t('portalStats.other')">{{ tariff.y.toFixed(2) }}%</td>
                    </tr>
                    <tr v-if="!tariffsChartPie.series[0].data.length" class="text-center">
                      <td colspan="3">{{ $t('aps.noDataToDisplay') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <section v-if="vouchersStatList ? vouchersStatList.length : false" class="section__stats">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.account') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.tariff') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.duration') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.code') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.used') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(voucher, index) in vouchersStatList" :key="voucher.id">
                  <td class="align-middle">{{ index + 1 }}</td>
                  <td class="align-middle">
                    <!--                    <span v-if="voucher.account">-->
                    <!--                      {{ getAccountNameByID(voucher.account) || '-' }}-->
                    <!--                    </span>-->
                    <span v-if="voucher.identity">
                      {{ voucher.identity || '-' }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle">
                    <span>
                      {{ getTariffNameByID(voucher.tariff) }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-if="voucher.start_at || voucher.expire_at">
                      {{ getDuration(voucher.start_at, voucher.expire_at) || '-' }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle">
                    <span v-if="voucher.code">
                      {{ voucher.code }}
                    </span>
                    <EmptyCellValue v-else />
                  </td>
                  <td class="align-middle">
                    <h5>
                      <span
                        class="badge font-weight-normal"
                        :class="{ 'badge-success': voucher.used === true, 'badge-warning': voucher.used === false }"
                      >
                        {{ voucher.used ? $t('general.yes') : $t('general.no') }}
                      </span>
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
      <div class="row d-flex justify-content-end pb-1">
        <WButton :warning="showStatistics"
          @click="showStatistics = !showStatistics"
        >
          {{ showStatistics ? $t('vouchers.backToVouchers') : $t('portalStats.stats') }}
        </WButton>
      </div>
    </div>

    <modal
      :backdrop="false"
      :title="isNew ? this.$t('vouchers.new') : this.$t('vouchers.edit')"
      @click.native="handleModalClick"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <loader-spinner v-if="isAccountForVoucherModalLoading"></loader-spinner>
      <h4 slot="modal-title" class="modal-title">{{ isNew ? $t('vouchers.new') : $t('vouchers.edit') }}</h4>

      <!--      <div class="form-group">-->
      <!--        <label for="account" class="required-mark">{{ $t('vouchers.account') }}</label>-->
      <!--        <select-->
      <!--          id="account"-->
      <!--          name="account"-->
      <!--          class="form-control"-->
      <!--          v-validate="'required'"-->
      <!--          v-model="voucher.account"-->
      <!--          :disabled="isDisable"-->
      <!--        >-->
      <!--          <option :key="account.id" v-for="account in userAccountsList" :value="account.id">-->
      <!--            {{ getAccountNameByID(account.id) }}-->
      <!--          </option>-->
      <!--        </select>-->
      <!--        <span-->
      <!--          v-tooltip.top-center="{ content: errors.first('account') }"-->
      <!--          v-show="errors.has('account')"-->
      <!--          class="help is-danger error_tooltip"-->
      <!--        >-->
      <!--          <i class="fa fa-warning text-error"></i>-->
      <!--        </span>-->
      <!--      </div>-->
      <div class="form-group">
        <label for="account" class="required-mark">{{ $t('vouchers.account') }}</label>
        <SelectComponentWithDeleteButton
          :cut-off-text-length="30"
          v-if="isModalOpen && isShowingAccountSelectInModal"
          @select="selectAccountInModal"
          id="account"
          :in-modal="true"
          @unselect="unselectAccountInModal"
          class="w-100 account-select-in-vouchers-modal"
          :class="{ 'is-danger-account-select-in-vouchers-modal': showNoAccountsSelectedErrInModal }"
          v-model="accountSelectedInSelectorInVoucherModal"
          track-by="identity"
          option-id-name="id"
          :async-function="requestAccounts.bind(this)"
          :placeholder="$t('vouchers.selectAccount')"
        ></SelectComponentWithDeleteButton>
        <!--        <span v-show="showNoAccountsSelectedErrInModal" class="help is-danger">{{ $t('marketing.workTimeTimezoneNoSelectError') }}</span>-->
        <span
          v-tooltip.top-center="{ content: $t('vouchers.noAccountSelectedInModalErr') }"
          v-show="showNoAccountsSelectedErrInModal"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="tariff" class="required-mark">{{ $t('vouchers.tariff') }}</label>
        <select
          id="tariff"
          name="tariff"
          class="form-control"
          v-validate="'required'"
          v-model="voucher.tariff"
          :disabled="isDisable"
        >
          <option :key="tariff.id" v-for="tariff in tariffsList" :value="tariff.id">
            {{ getTariffNameByID(tariff.id) }}
          </option>
        </select>
        <span
          v-tooltip.top-center="{ content: errors.first('tariff') }"
          v-show="errors.has('tariff')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="duration" class="required-mark">{{ $t('vouchers.duration') }} ({{ $t('general.insec') }})</label>
        <input
          id="duration"
          name="duration"
          ref="duration"
          type="number"
          class="form-control input"
          :readonly="isDisable || !isNew"
          v-model="voucher.duration"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('duration') }"
          v-show="errors.has('duration')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
        <div v-if="!isDisable && isNew" class="d-flex flex-wrap mt-h">
          <WButton success
            customClass="mr-h"
            @click="handleDurationSelect(1)"
          >
            {{ $t('general.durationDay') }}
          </WButton>
          <WButton success
            customClass="mr-h"
            @click="handleDurationSelect(3)"
          >
            {{ $t('portal.duration3Days') }}
          </WButton>
          <WButton success
            customClass="mr-h"
            @click="handleDurationSelect(7)"
          >
            {{ $t('general.durationWeek') }}
          </WButton>
          <WButton success
            @click="handleDurationSelect(31)"
          >
            {{ $t('general.durationMonth') }}
          </WButton>
        </div>
      </div>

      <div class="form-group">
        <label for="used" class="required-mark">{{ $t('vouchers.used') }}</label>
        <select
          id="used"
          name="used"
          class="form-control"
          v-validate="'required'"
          v-model="voucher.used"
          :disabled="isDisable"
        >
          <option :value="true">{{ $t('general.yes') }}</option>
          <option :value="false">{{ $t('general.no') }}</option>
        </select>
        <span
          v-tooltip.top-center="{ content: errors.first('used') }"
          v-show="errors.has('used')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="handleSave"
          :disabled="errors.any() || muteEdit"
        >
          <span :class="{ invisible: muteEdit }">{{ isNew ? $t('general.create') : $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="cancelEditMode"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleCloseDeleteDialog.bind(this)"
      :handle-delete="deleteVoucher.bind(this)"
      :target-text="`${voucher.code} (${getTariffNameByID(voucher.tariff)})`"
    />

    <close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import Modal from '../../components/Modal.vue';
// import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import CloseDialog from '../../components/close-dialog.vue';
import DatePicker from '../../components/date-picker.vue';
import SavePngButton from '../../components/save-png-button.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
import portalVouchersService from '../../services/portalVouchersService';
import portalTariffsService from '../../services/portalTariffsService';
import portalUserAccountsService from '../../services/portalUserAccountsService';
import portalStatsService from '../../services/portalStatsService';
import SelectComponentWithDeleteButton from '../../components/Universal/select/SelectComponentWithDeleteButton.vue';

const watcher = new helpers.ChangesWatcher();

export default {
  name: 'Vouchers',
  components: {
    Modal,
    // TablePaginationV2,
    DeleteDialog,
    CloseDialog,
    DatePicker,
    Multiselect,
    SavePngButton,
    EmptyCellValue,
    SelectComponentWithDeleteButton,
    TablePaginationV3
  },
  data() {
    return {
      showTablePaginationV3Component: true,
      showNoAccountsSelectedErrInModal: false,
      isShowingAccountSelectInModal: true,
      accountSelectedInSelectorInVoucherModal: {},
      isShowingAccountsFilter: true,
      submitButton: true,
      drPickerDate: {
        startDate: moment().startOf('week').valueOf(),
        endDate: moment().endOf('week').valueOf()
      },
      showStatistics: false,
      offset: 0,
      canLoadMoreVouchers: true,
      totalResultVouchers: false,
      isModalOpen: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isNew: true,
      vouchersAmount: 0,
      voucher: {} /* setting initial value to false breaks something */,
      newVoucher: {
        account: '',
        tariff: '',
        duration: '',
        id: '',
        used: ''
      },
      filters: {
        accounts: {
          // identity: this.$t('portalStats.allAccounts')
        },
        tariffs: { name: this.$t('portalStats.allTariffs') },
        limit: 30,
        offset: 0,
        sort_by: 'account',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      },
      tariffsChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },

        series: [
          {
            name: 'Voucher',
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      }
    };
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    redrawAccountSelectInModal() {
      this.isShowingAccountSelectInModal = false;
      this.$nextTick(() => {
        this.isShowingAccountSelectInModal = true;
      });
    },
    // resetAllFilters() {
    //   this.filters = {
    //     accounts: {
    //       // identity: this.$t('portalStats.allAccounts')
    //     },
    //     tariffs: {name: this.$t('portalStats.allTariffs')},
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'account',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   };
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
      this.filters = {
        accounts: {
          // identity: this.$t('portalStats.allAccounts')
        },
        tariffs: {name: this.$t('portalStats.allTariffs')},
        limit: 30,
        offset: 0,
        sort_by: 'account',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        // location: {
        //   data: {},
        //   with_childs: false,
        // }
      };

      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }

      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    async requestAccounts(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      return portalUserAccountsService.requestAccounts(searchQuery);
    },
    getAccountObjectById(accountId) {
      return portalUserAccountsService.getUserAccountByIdFromVouchers(this, accountId);
    },
    toggleShowStatistics() {
      // console.log('show statistics');
      this.showStatistics = !this.showStatistics;
      this.clearQuery();
      this.$nextTick(() => {
        if (this.showStatistics === true) {
          // когда переходим в подраздел со статистикой
          // this.cleanAndLoadAccountsList();
          this.cleanAndLoadTarrifsList();
          this.getVouchersStat();
        } else if (this.showStatistics === false) {
          // когда переходим обратно в подраздел с ваучерами
          // this.cleanAndLoadAccountsList();
          this.cleanAndLoadTarrifsList();
          this.cleanAndLoadVouchersList();
        }
      });
    },
    cleanAndLoadAccountsList() {
      this.$store.commit('portalUserAccountsListClean');
      if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccounts(this);
    },
    cleanAndLoadTarrifsList() {
      this.$store.commit('portalTariffsListClean');
      if (this.tariffsList.length < 1) portalTariffsService.getTariffs(this);
    },
    cleanAndLoadVouchersList() {
      this.$store.commit('portalVouchersListClean');
      // portalVouchersService.getVouchers(this, { query: true });
      portalVouchersService.getVouchers(this, { query: true, useLocationFilter: true });
    },
    // cleanAndLoadVouchersListStat() {
    //   this.getVouchersStat();
    // },
    setVouchersAmount(amount) {
      this.vouchersAmount = amount;
    },
    getVouchersStat() {
      this.submitButton = true;
      portalStatsService.getVouchersStat(this, 'voucher');
    },
    getVouchersStatAccountsAndTarifs() {
      this.submitButton = true;
      // this.cleanAndLoadAccountsList();
      this.cleanAndLoadTarrifsList();
      portalStatsService.getVouchersStat(this, 'voucher');
    },
    changeProfile() {
      this.submitButton = false;
    },
    selectAccount(account) {
      this.submitButton = false;
      // this.filters.accounts.identity = this.$t('portalStats.allAccounts');
    },
    unselectAccount() {
      // console.log('unselected');
      this.submitButton = false;
      // this.filters.accounts = {}
      // if (this.filters.accounts.identity !== this.$t('portalStats.allAccounts')) {
      //   this.filters.accounts.identity = this.$t('portalStats.allAccounts');
      //   //this.$forceUpdate()
      //   this.redrawAccountsFilter();
      // }
      // // console.log(this.filters.accounts)
      this.filters.accounts = {};
    },
    selectAccountInModal() {
      this.showNoAccountsSelectedErrInModal = false;
      this.voucher.account = this.accountSelectedInSelectorInVoucherModal.id;
    },
    unselectAccountInModal() {
      this.accountSelectedInSelectorInVoucherModal = {};
      this.voucher.account = '';
    },
    redrawAccountsFilter() {
      this.isShowingAccountsFilter = false;
      this.$nextTick(() => {
        this.isShowingAccountsFilter = true;
      });
    },
    handleDateChangeForStat() {
      this.submitButton = false;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalVouchersService.getVouchers(this, { query: true });
      portalVouchersService.getVouchers(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMoreVouchers = true;
      this.$store.commit('portalVouchersListClean');
    },
    openModal(isNew, voucher) {
      this.showNoAccountsSelectedErrInModal = false;
      this.accountSelectedInSelectorInVoucherModal = {};
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        this.isNew = true;
        this.voucher = { ...this.newVoucher };
      } else {
        this.isNew = false;
        this.voucher = { ...voucher };
        this.voucher.duration = this.voucher.expire_at - this.voucher.start_at;
        delete this.voucher.start_at;
        delete this.voucher.expire_at;
        const accountId = this.voucher.account;
        this.getAccountObjectById(accountId);
      }
      watcher.reset();
    },
    handleSave() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.voucher.account == '') {
            this.showNoAccountsSelectedErrInModal = true;
          } else if (this.isNew) {
            this.createVoucher(this.voucher);
          } else {
            this.updateVoucher(this.voucher);
          }
        } else if (this.voucher.account == '') {
          this.showNoAccountsSelectedErrInModal = true;
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      this.isDeleteOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    correctValuesTypes(voucher) {
      const copy = { ...voucher };
      const duration = parseInt(copy.duration, 10);
      copy.start_at = moment().unix().valueOf();
      copy.expire_at = copy.start_at + duration;
      delete copy.duration;
      return copy;
    },
    updateVoucher(voucher) {
      portalVouchersService.updateVoucher(this, this.correctValuesTypes({ ...voucher }));
    },
    createVoucher(voucher) {
      portalVouchersService.createVoucher(this, helpers.removeID(this.correctValuesTypes({ ...voucher })));
    },
    deleteVoucher() {
      portalVouchersService.deleteVoucher(this, this.voucher);
    },
    getTariffNameByID(id) {
      if (this.tariffsList) {
        const result = this.tariffsList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getAccountNameByID(id) {
      if (this.userAccountsList) {
        const result = this.userAccountsList.find((item) => item.id === id);
        if (result) {
          return result.identity;
        }
        return '';
      }
      return '';
    },
    getDuration(start, end) {
      if (!start || !end) {
        return '';
      }

      const timeFormat = 'D MMM YYYY, HH:mm';
      const startDate = moment.unix(start).format(timeFormat);
      const endDate = moment.unix(end).format(timeFormat);
      const fromPreposition = this.$t('general.from');
      const toPreposition = this.$t('general.till');

      return `${fromPreposition} ${startDate} ${toPreposition} ${endDate}`;
    },
    // getWithQuery() {
    //   this.clearQuery();
    //   portalVouchersService.getVouchers(this, { query: true });
    // },
    getWithQuery() {
      this.clearQuery();
      // this.cleanAndLoadAccountsList();
      this.cleanAndLoadTarrifsList();
      // portalVouchersService.getVouchers(this, { query: true });
      portalVouchersService.getVouchers(this, { query: true, useLocationFilter: true });
    },
    handleDurationSelect(days) {
      this.voucher.duration = moment(0).add(days, 'days').unix();
      this.errors.remove('duration');
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    },
    // formatPieToCSV(graphData, graphName = '') {
    //   if (graphData.series[0].data.length === 0) {
    //     return [];
    //   }
    //   let name = 'Type';
    //   const count = this.$t('portalStats.count');
    //   const percent = this.$t('portalStats.percent');
    //   if (graphName === this.$t('portalStats.tariffs')) {
    //     name = this.$t('portalStats.tariffs');
    //   }
    //
    //   const result = [];
    //   const chartName = {
    //     A: name,
    //     B: this.$t('portalStats.count'),
    //     C: this.$t('portalStats.percent'),
    //     D: '',
    //     E: ''
    //   };
    //
    //   result.push(chartName);
    //   for (const item of graphData.series[0].data) {
    //     const itemObj = {};
    //     itemObj.A = '';
    //     if (item.name) {
    //       itemObj.A = item.name;
    //     }
    //     itemObj[count] = '';
    //     if (item.amount) {
    //       itemObj.B = item.amount;
    //     }
    //     itemObj[percent] = '';
    //     if (item.y) {
    //       itemObj.C = item.y.toFixed(2);
    //     }
    //     result.push(itemObj);
    //   }
    //   result.push({});
    //
    //   result.push({
    //     A: this.$t('vouchers.account'),
    //     B: this.$t('vouchers.tariff'),
    //     C: this.$t('vouchers.duration'),
    //     D: this.$t('vouchers.code'),
    //     E: this.$t('vouchers.used')
    //   });
    //   if (this.vouchersStatList?.length) {
    //     this.vouchersStatList.forEach((row) => {
    //       result.push({
    //         A: this.getAccountNameByID(row.account) || '-',
    //         B: this.getTariffNameByID(row.tariff) || '-',
    //         C: this.getDuration(row.start_at, row.expire_at) || '-',
    //         D: row.code || '-',
    //         E: row.used ? this.$t('general.yes') : this.$t('general.no')
    //       });
    //     });
    //   }
    //   return result;
    // },
    formatPieToCSV(graphData, graphName = '') {
      // фикс скачивания pdf
      if (graphData.series[0].data.length === 0) {
        return [];
      }
      let name = 'Type';
      const count = this.$t('portalStats.count');
      const percent = this.$t('portalStats.percent');
      if (graphName === this.$t('portalStats.tariffs')) {
        name = this.$t('portalStats.tariffs');
      }

      const result = [];
      const chartName = {
        A: name,
        B: this.$t('portalStats.count'),
        C: this.$t('portalStats.percent'),
        D: '',
        E: ''
      };

      result.push(chartName);
      for (const item of graphData.series[0].data) {
        const itemObj = {};
        itemObj.A = '';
        if (item.name) {
          itemObj.A = item.name;
        } else {
          itemObj.A = '';
        }
        // itemObj[count] = '';
        if (item.amount) {
          itemObj.B = item.amount;
        } else {
          itemObj.B = '';
        }
        // itemObj[percent] = '';
        if (item.y) {
          itemObj.C = item.y.toFixed(2);
        } else {
          itemObj.C = '';
        }
        itemObj.D = '';
        itemObj.E = '';
        result.push(itemObj);
      }
      result.push({
        A: '',
        B: '',
        C: '',
        D: '',
        E: ''
      });

      result.push({
        A: this.$t('vouchers.account'),
        B: this.$t('vouchers.tariff'),
        C: this.$t('vouchers.duration'),
        D: this.$t('vouchers.code'),
        E: this.$t('vouchers.used')
      });
      if (this.vouchersStatList?.length) {
        this.vouchersStatList.forEach((row) => {
          result.push({
            // A: this.getAccountNameByID(row.account) || '-'
            A: row.identity || '-',
            B: this.getTariffNameByID(row.tariff) || '-',
            C: this.getDuration(row.start_at, row.expire_at) || '-',
            D: row.code || '-',
            E: row.used ? this.$t('general.yes') : this.$t('general.no')
          });
        });
      }
      return result;
    },
    formatPieToExcel(graphData, graphName = 'data') {
      const result = {};
      if (graphData.series[0].data.length === 0) {
        return {};
      }
      let name = 'Type';
      const count = this.$t('portalStats.count');
      const percent = this.$t('portalStats.percent');
      if (graphName === this.$t('portalStats.tariffs')) {
        name = this.$t('portalStats.tariffs');
      }

      result[name] = [];
      const itemsToExcel = result[name];
      itemsToExcel.push([name, count, percent]);
      for (const item of graphData.series[0].data) {
        let name = '';
        let count = '';
        let percent = '';

        if (item.name) {
          name = item.name;
        }

        if (item.amount) {
          count = item.amount;
        }

        if (item.y) {
          percent = item.y.toFixed(2);
        }
        itemsToExcel.push([name, count, percent]);
      }
      itemsToExcel.push(
        [],
        [
          this.$t('vouchers.account'),
          this.$t('vouchers.tariff'),
          this.$t('vouchers.duration'),
          this.$t('vouchers.code'),
          this.$t('vouchers.used')
        ]
      );
      if (this.vouchersStatList?.length) {
        this.vouchersStatList.forEach((row) => {
          itemsToExcel.push([
            // this.getAccountNameByID(row.account) || '-',
            row.identity || '-',
            this.getTariffNameByID(row.tariff),
            this.getDuration(row.start_at, row.expire_at) || '-',
            row.code,
            row.used ? this.$t('general.yes') : this.$t('general.no')
          ]);
        });
      }

      return result;
    }
  },
  watch: {
    voucher: {
      deep: true,
      handler() {
        watcher.tick();
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'account' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    isAccountForVoucherModalLoading() {
      return this.$store.state.getAccountByIdFromVoucherRequestExecuting;
    },
    userAccountsStatList() {
      const list = this.$store.state.portalUserAccountsList || [];
      if (list.length && list[0].id) {
        list.unshift({ identity: this.$t('portalStats.allAccounts') });
      }
      return list;
    },
    muteEdit() {
      return this.$store.state.mutePortalVoucherEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    vouchersList() {
      return this.$store.state.portalVouchersList;
    },
    vouchersStatList() {
      return this.$store.state.portalVouchersStatList;
    },
    userAccountsList() {
      return this.$store.state.portalUserAccountsList;
    },
    tariffsList() {
      return this.$store.state.portalTariffsList;
    },
    tariffsStatList() {
      const list = this.$store.state.portalTariffsList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allTariffs') });
      }
      return list;
    },
    isVouchersLoading() {
      return (
        this.$store.state.loadingPortalVouchers ||
        this.$store.state.loadingPortalUserAccounts ||
        this.$store.state.loadingPortalTariffs ||
        this.$store.state.loadingPortalVouchersStat
      );
    }
  },
  // created() {
  //   this.$store.commit('portalTariffsListClean');
  //   this.$store.commit('portalUserAccountsListClean');
  //   this.clearQuery();
  //   // if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccounts(this);
  //   if (this.tariffsList.length < 1) portalTariffsService.getTariffs(this);
  //   portalVouchersService.getVouchers(this, { query: true });
  //   // this.getVouchersStat();
  // }
  created() {
    this.$store.commit('portalTariffsListClean');
    this.$store.commit('portalUserAccountsListClean');
    this.clearQuery();
    // if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccounts(this);
    if (this.tariffsList.length < 1) portalTariffsService.getTariffs(this);

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }
    // portalVouchersService.getVouchers(this, { query: true });
    portalVouchersService.getVouchers(this, { query: true, useLocationFilter: true });
    // this.getVouchersStat();
  }
};
</script>

<style lang="scss" scoped>
.loader-backdrop-portalvouchers:after {
  position: absolute;
  top: 30px;
}
.section__stats {
  padding-top: 20px;
}
.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.submit-width_full {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
  & .download-button-in-voucher-stats {
    margin-top: 28px;
    height: 38px;
    width: 100%;
  }
}
.datepicker_width_full {
  width: 100%;
}
</style>
<style>
/*    .account-select-in-vouchers-filters {*/
/*  min-height: 40px !important;*/
/*}*/

/*.account-select-in-vouchers-filters .select__control {*/
/*  padding-top: 1px !important;*/
/*  padding-bottom: 1px !important;*/
/*  max-height: 300px !important;*/
/*  min-height: 40px !important;*/
/*  align-items: center;*/

/*}*/

/*.account-select-in-vouchers-filters .select__input {*/
/*  height: 20px !important;*/
/*  margin-bottom: 0px !important;*/

/*}*/

/*.account-select-in-vouchers-filters .arrow {*/
/*  min-height: 27px !important;*/
/*}*/

.account-select-in-vouchers-filters {
  max-height: 40px !important;
}

.account-select-in-vouchers-filters .select__tag {
  overflow-x: hidden !important;
}
.account-select-in-vouchers-filters .select__list-container {
  min-width: 500px !important;
  overflow-x: hidden !important;
}

.is-danger-account-select-in-vouchers-modal {
  border: red 1px solid !important;
}
</style>
